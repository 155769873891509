import Axios from "axios";
import '.././config';

async function updateForumSettings(uid, settings) {
  const configResponse = await Axios.get(
    `${global.config.domain}/forum-api/api/config`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const csrfToken = configResponse.data.csrf_token;

  Axios.put(
    `${global.config.domain}/forum-api/api/v3/users/${uid}/settings`,
    {
      settings: settings,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "x-csrf-token": csrfToken,
      },
    }
  )
    .then((response) => {
      const userSettings = response.data;
      return userSettings;
    })
    .catch((error) => {
      console.error(error);
    });
}

export default updateForumSettings;
