import React, { useEffect } from 'react';
import './Calendar.css';
import '../config';
import Axios from "axios";

function Calendar() {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Create script element
    const script = document.createElement('script');
    script.src = 'https://dist.eventscalendar.co/main.js';
    script.async = true;

    // Append script to the body
    document.body.appendChild(script);

    // Cleanup function to remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  let proxyURL = `${global.config.domain}/express-api`;

  useEffect(() => {
    const updateChecklistStep = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
        const response = await Axios.put(
          proxyURL + "/update-checklist-step",
          { step: "eventsCalendar" },
          { withCredentials: true }
        );
        console.log("Checklist step updated:", response.data);
      } catch (error) {
        console.error("Error updating checklist step:", error);
      }
    };
  
    updateChecklistStep();
  }, []);

  return (
    <>
      <div
        id="hero-img-calendar"
        role="img"
        aria-label="Plants in a greenhouse"
      >
        <h1>Events Calendar</h1>
      </div>
      <div className="newClass">
        <div data-events-calendar-app data-project-id="proj_3yttjF0R8J9XrnedNM5WF" ></div>
        <script type="text/javascript" src="//dist.eventscalendar.co/embed.js"></script>
      </div>
    </>
  );
}

export default Calendar;
