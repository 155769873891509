import "./SignUp.css";
import { useEffect, useState } from "react";
import Axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import PrimaryButton from "../components/PrimaryButton.js";
import { useAuth } from "../contexts/AuthContext";
import { Box, CircularProgress } from "@mui/material";
import updateForumSettings from "../utils/updateForumSettings";
import '.././config';

function SignUp() {
  const [usernameString, setUsername] = useState("");
  const [emailString, setEmail] = useState("");
  const [passwordString, setPassword] = useState("");
  const [confirmPasswordString, setConfirmPassword] = useState("");
  const [tosCheck, setTos] = useState(false);
  const [newsletterCheck, setNewsNotif] = useState(false);
  const {
    setIsAuthenticated,
    setUser,
    isAuthenticated,
    user,
    setUserSettings,
  } = useAuth();
  const [nameString, setName] = useState("");
  const [currentSection, setCurrentSection] = useState(1);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const navigate = useNavigate();

  // Javascript compiler go brrrr
  let username,
    email,
    password,
    confirmPassword,
    name,
    usernameLabel,
    emailLabel,
    passwordLabel,
    confirmPasswordLabel,
    nameLabel,
    error,
    error2;

  useEffect(() => {
    window.scrollTo(0, 0);

    // DOM Element Selection
    username = document.querySelector(".su-username-login-input");
    email = document.querySelector(".su-email-login-input");
    password = document.querySelector(".su-password-login-input");
    confirmPassword = document.querySelector(".su-confirm-password");
    name = document.querySelector(".su-name");
    usernameLabel = document.querySelector(".su-username-label");
    emailLabel = document.querySelector(".su-email-label");
    passwordLabel = document.querySelector(".su-password-label");
    confirmPasswordLabel = document.querySelector(".su-confirm-password-label");
    nameLabel = document.querySelector(".su-name-label");
    error = document.getElementById("su-error-text");
    error2 = document.getElementById("su-error-text2");

    // The next 200 lines is all for text box label animations.
    // Cool feature but lots of duplicated code.
    // TODO: Refactor logic into a funciton for re-use.
    username.addEventListener("focus", () => {
      usernameLabel.style.top = "10px";
      usernameLabel.style["z-index"] = "2";
    });
    username.addEventListener("blur", () => {
      if (username.value.length === 0) {
        usernameLabel.style.top = "38px";
        usernameLabel.style["z-index"] = "-1";
      } else {
        username.style.borderColor = "#666";
        usernameLabel.style.color = "#000000de";
        error.style.visibility = "hidden";
      }
    });
    email.addEventListener("focus", () => {
      emailLabel.style.top = "10px";
      emailLabel.style["z-index"] = "2";
    });
    email.addEventListener("blur", () => {
      if (email.value.length === 0) {
        emailLabel.style.top = "38px";
        emailLabel.style["z-index"] = "-1";
      } else {
        email.style.borderColor = "#666";
        emailLabel.style.color = "#000000de";
        error.style.visibility = "hidden";
      }
    });
    password.addEventListener("focus", (e) => {
      passwordLabel.style.top = "10px";
      passwordLabel.style["z-index"] = "2";
    });
    password.addEventListener("blur", () => {
      if (password.value.length === 0) {
        passwordLabel.style.top = "38px";
        passwordLabel.style["z-index"] = "-1";
      } else {
        password.style.borderColor = "#666";
        passwordLabel.style.color = "#000000de";
        confirmPassword.style.borderColor = "#666";
        confirmPasswordLabel.style.color = "#000000de";
        error.style.visibility = "hidden";
      }
    });
    confirmPassword.addEventListener("focus", () => {
      confirmPasswordLabel.style.top = "12px";
      confirmPasswordLabel.style["z-index"] = "2";
    });
    confirmPassword.addEventListener("blur", () => {
      if (confirmPassword.value.length === 0) {
        confirmPasswordLabel.style.top = "38px";
        confirmPasswordLabel.style["z-index"] = "-1";
      } else {
        confirmPassword.style.borderColor = "#666";
        confirmPasswordLabel.style.color = "#000000de";
        password.style.borderColor = "#666";
        passwordLabel.style.color = "#000000de";
        error.style.visibility = "hidden";
      }
    });
    name.addEventListener("focus", () => {
      nameLabel.style.top = "10px";
      nameLabel.style["z-index"] = "2";
    });
    name.addEventListener("blur", () => {
      if (name.value.length === 0) {
        nameLabel.style.top = "38px";
        nameLabel.style["z-index"] = "-1";
      } else {
        name.style.borderColor = "#666";
        nameLabel.style.color = "#000000de";
        error2.style.visibility = "hidden";
      }
    });
  }, []);

  const proxyUrl = `${global.config.domain}/express-api/`;
  const forumProxy = `${global.config.domain}/forum-api/`;

  // Starting point of page
  async function handleInput() {
    // Regular expressions
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const usernameAlphanumericRegex = /^[a-zA-Z0-9_]+$/;
    const passwordCharacterCountRegex = /^.{8,}$/;
    const passwordCapitalLetterRegex = /[A-Z]/;
    const passwordNumberSymbolRegex = /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?])/;

    // DOM Element Selection
    name = document.querySelector(".su-name");
    username = document.querySelector(".su-username-login-input");
    email = document.querySelector(".su-email-login-input");
    password = document.querySelector(".su-password-login-input");
    confirmPassword = document.querySelector(".su-confirm-password");
    nameLabel = document.querySelector(".su-name-label");
    usernameLabel = document.querySelector(".su-username-label");
    emailLabel = document.querySelector(".su-email-label");
    passwordLabel = document.querySelector(".su-password-label");
    confirmPasswordLabel = document.querySelector(".su-confirm-password-label");
    error = document.getElementById("su-error-text");
    const tosCheckbox = document.querySelector('input[name="tos-check"]');
    const newsletterCheckbox = document.querySelector('input[name="newsletter-check"]');
    const adultCheckbox = document.querySelector('input[name="adult-check"]');

    // Handle empty fields
    if (
      nameString.length === 0 ||
      usernameString.length === 0 ||
      emailString.length === 0 ||
      passwordString.length === 0 ||
      confirmPasswordString.length === 0
    ) {
      if (nameString.length === 0) {
        name.style.borderColor = "#b50000";
        nameLabel.style.color = "#b50000";
      }
      if (usernameString.length === 0) {
        username.style.borderColor = "#b50000";
        usernameLabel.style.color = "#b50000";
      }
      if (emailString.length === 0) {
        email.style.borderColor = "#b50000";
        emailLabel.style.color = "#b50000";
      }
      if (passwordString.length === 0) {
        password.style.borderColor = "#b50000";
        passwordLabel.style.color = "#b50000";
      }
      if (confirmPasswordString.length === 0) {
        confirmPassword.style.borderColor = "#b50000";
        confirmPasswordLabel.style.color = "#b50000";
      }
      error.style.visibility = "visible";
      error.innerHTML = "Please fill out all required fields.";
    }

    // Handle non-alphanumeric username
    else if (!usernameAlphanumericRegex.test(usernameString)) {
      username.style.borderColor = "#b50000";
      usernameLabel.style.color = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML = "Usernames can only contain letters, numbers, and underscores.";
    }

    // TODO: Handle swear words

    // Handle invalid email
    else if (!emailRegex.test(emailString)) {
      email.style.borderColor = "#b50000";
      emailLabel.style.color = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML = "Please enter a valid email address.";
    }
    // Handle invalid password
    else if (!passwordCharacterCountRegex.test(passwordString)) {
      password.style.borderColor = "#b50000";
      passwordLabel.style.color = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML = "Please enter a password with at least 8 characters.";
    } else if (!passwordCapitalLetterRegex.test(passwordString)) {
      password.style.borderColor = "#b50000";
      passwordLabel.style.color = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML =
        "Please enter a password with at least 1 capital letter.";
    } else if (!passwordNumberSymbolRegex.test(passwordString)) {
      password.style.borderColor = "#b50000";
      passwordLabel.style.color = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML =
        "Please enter a password with at least 1 number and 1 symbol.";
    }
    // Handle passwords do not match
    else if (passwordString !== confirmPasswordString) {
      confirmPassword.style.borderColor = "#b50000";
      confirmPasswordLabel.style.color = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML = "Please make sure your passwords match.";
    }
    // Handle didn't agree to terms of service
    else if (!tosCheckbox.checked) {
      tosCheckbox.style.borderColor = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML =
        "Please agree to our Terms of Service and Privacy Policy to continue.";
    } else if (!adultCheckbox.checked) {
      adultCheckbox.style.borderColor = "#b50000";
      error.style.visibility = "visible";
      error.innerHTML = "Please verify that you are at least 18 years of age.";
    }
    // Success, generating account...
    else {
      console.log("Passed field validation.");
      error.style.visibility = "hidden";

      try {
        setIsSigningIn(true);
  
        // 1. Check account availability
        var availability = await checkAvailability(
          usernameString,
          emailString
        );
  
        if(availability === 200) {
          console.log("Account is available.");
  
          // 2. Create new account object with NodeBB API
          var signUpResponse = await handleSignUp(
            usernameString,
            passwordString,
            emailString
          );
  
          if(signUpResponse.data.response.uid > 1) {
            console.log("Account created succesfully.");
  
            // 3. Log the user into their account
            const loginResponse = await handleLogIn(usernameString, passwordString);
            if(loginResponse > 1) {
              console.log("Client auth established");
  
              // 4. Add custom fields to their account object
              const customObjectResponse = await upsertCustomFields(
                signUpResponse.data.response.uid,
                nameString,
                emailString,
                newsletterCheckbox.checked
              );
              if(customObjectResponse.status === 200) {
                console.log("Custom fields added.");
  
                setUser(customObjectResponse.data.user);
                console.log("Local cookie set.")
  
                // 5. Update NodeBB settings to the default values 
                const forumResponse = await upsertForumFields(signUpResponse.data.response.uid);
                console.log("Set default privacy settings.")
                
                // 6. Notify admin team
                await sendEmail(customObjectResponse.data.user);
                console.log("Notified admin");
  
                navigate("/getting-started");
              }
              else {
                // Custom objects failed
                error.style.visibility = "visible";
                error.innerHTML = "Account creation failed. Please contact support@azfarmtoschool.org";
              }
            }
            else {
              // Login failed
              error.style.visibility = "visible";
              error.innerHTML = "Account creation failed. Please contact support@azfarmtoschool.org";
            }
          }
          // NodeBB signup failed
          else {
            error.style.visibility = "visible";
            error.innerHTML = "Account creation failed. Please try again in a few minutes, or contact support@azfarmtoschool.org if issues persist.";
          }
        }
        // Account not available
        else {
          error.style.visibility = "visible";
          error.innerHTML = "Username or email is already taken.";
        }
      }
      catch (err) {
        error.style.visibility = "visible";
        error.innerHTML = "Account creation failed. Please contact support@azfarmtoschool.org";
      } finally {
        setIsSigningIn(false);
      }
    }
  }

  async function checkAvailability(usernameString, emailString) {
    try {
      const response = await Axios.post(`${proxyUrl}is-account-available`, {
        username: usernameString,
        email: emailString,
      });
  
      // Return the status code as an integer
      return response.status;
    } catch (error) {
      console.error('Error checking account availability:', error);
  
      // If the error response is available, return its status code; otherwise, return a default error code
      return error.response ? error.response.status : 500;
    }
  }

  async function handleSignUp(userName, password, email) {
    try {
      const requestBody = {
        username: userName,
        password: password,
        email: email,
      };

      const signUpResponse = await Axios.post(`${proxyUrl}sign-up`, requestBody);

      return signUpResponse;
    } catch (error) {
      setIsSigningIn(false);
      setIsAuthenticated(false);
      error.style.visibility = "visible";
      error.innerHTML = "Error generating your account. " + error.message;
      return;
    }
  }

  async function sendEmail(userData) {
    try {
      const response = await Axios.post(`${proxyUrl}new-user-email`, {
        fullName: userData.fullname,
        username: userData.username,
        email: userData.email,
      });
    } catch {
      setIsSigningIn(false);
      setIsAuthenticated(false);
      error.style.visibility = "visible";
      error.innerHTML =
        "Error generating your account. Please try again later.";
      return;
    }
  }

  async function handleLogIn(usernameString, passwordString) {
    try {
      // Login. Might be redundant after creating the account in same session.
      const loginResponse = await Axios.post(
        `${forumProxy}api/v3/utilities/login`,
        {
          username: usernameString,
          password: passwordString,
        }
      );

      if (loginResponse.status !== 200 || loginResponse.data.error) {
        console.log(loginResponse.data.error);
        throw new Error(loginResponse.data.error);
      }

      // Get user object, set client auth
      const userResponse = await Axios.get(`${proxyUrl}user`, {
        withCredentials: true,
      });
      const userData = userResponse.data;

      setIsAuthenticated(true);
      setUser(userData);

      return userData.uid;
    } catch {
      setIsSigningIn(false);
      setIsAuthenticated(false);
      error.style.visibility = "visible";
      error.innerHTML =
        "Error generating your account. Please try again later.";
      return;
    }
  }

  async function upsertCustomFields(uid, fullName, email, newsletter) {
    try 
    {
      const requestBody = {
        uid: uid,
        fullname: fullName,
        email: email,
        receivenewsletter: newsletter,
      };

      const setUpResopnse = await Axios.put(`${proxyUrl}new-user`, requestBody);

      return setUpResopnse;
    } 
    catch 
    {
      setIsSigningIn(false);
      setIsAuthenticated(false);
      error.style.visibility = "visible";
      error.innerHTML =
        "Error generating your account. Please try again later.";
      return;
    }
  }

  async function upsertForumFields(uid) {
    try {
      const forumRequestSettings = {
        showemail: "1",
        showfullname: "1"
      };

      const forumResponse = await updateForumSettings(uid, forumRequestSettings);

      return forumResponse;
    }
    catch {
      setIsSigningIn(false);
      setIsAuthenticated(false);
      error.style.visibility = "visible";
      error.innerHTML =
        "Error generating your account. Please try again later.";
      return;
    }
  }

  // TODO: Clean this up. The "sections" thing didn't pan out.
  return (
    <div className="sign-up-page-body">
      <div className="sign-up-body sign-up-section" id="all-sections">
        <div
          className={`first-section sign-up-sec ${
            currentSection > 1
              ? "before"
              : currentSection === 1
              ? "active"
              : "after"
          }`}
        >
          {" "}
          <h1>Sign up</h1>

          <div>Already have an account? <a href="/login">Log in</a></div>

          <div className="name-div">
            <label htmlFor="su-name" className="su-name-label">
              Full Name*
            </label>
            <input
              type="text"
              name="su-name"
              className="su-name"
              id="su-name"
              value={nameString}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="name-div">
            <label htmlFor="su-username" className="su-username-label">
              Username*
            </label>
            <input
              type="text"
              name="su-username"
              className="su-username-login-input"
              value={usernameString}
              style={{ width: "100%" }}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="name-div">
            <label htmlFor="su-email" className="su-email-label">
              Email*
            </label>
            <input
              type="text"
              name="su-email"
              className="su-email-login-input"
              value={emailString}
              style={{ width: "100%" }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="name-div">
            <label htmlFor="su-password" className="su-password-label">
              Password*
            </label>
            <input
              type="password"
              name="su-password"
              className="su-password-login-input"
              value={passwordString}
              style={{ width: "100%" }}
              onChange={(e) => {
                e.preventDefault();
                e.target.focus({ preventScroll: true });
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="name-div">
            <label
              htmlFor="su-confirm-password"
              className="su-confirm-password-label"
            >
              Confirm Password*
            </label>
            <input
              type="password"
              name="su-confirm-password"
              className="su-confirm-password"
              id="conf-password"
              value={confirmPasswordString}
              style={{ width: "100%", marginTop: "0px" }}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <div className="checkbox-section">
            <div className="check-div">
              <input
                type="checkbox"
                name="tos-check"
                className="check-box"
                onChange={(e) => {
                  setTos(e.target.checked);
                }}
              />
              <label htmlFor="tos-check">
                I agree to the{" "}
                <a href={`${global.config.domain}/terms-of-service`} target="_blank">
                  Terms of Service
                </a>
                * and{" "}
                <a href={`${global.config.domain}/privacy-policy`} target="_blank">
                  Privacy Policy
                </a>
                *.
              </label>
            </div>
            <div className="check-div">
              <input
                type="checkbox"
                name="adult-check"
                className="check-box"
                onChange={(e) => {
                  setTos(e.target.checked);
                }}
              />
              <label htmlFor="adult-check">
                I certify that I am at least 18 years of age.*
              </label>
            </div>
            <div className="check-div">
              <input
                type="checkbox"
                name="newsletter-check"
                className="check-box"
                onChange={(e) => {
                  setNewsNotif(e.target.checked);
                }}
              />
              <label htmlFor="newsletter-check">
                Sign me up for the Arizona Farm to School Network{" "}
                <a href={`${global.config.domain}/newsletter`} target="_blank">
                  newsletter
                </a>
                .
              </label>
            </div>
          </div>
          <div className="su-error-line1">
            <p id="su-error-text"></p>
          </div>
          <br />
          <div className="sign-up-options">
          <PrimaryButton
            backgroundColor="#668c3c"
            text="Sign up"
            onClick={(e) => {
              if (!isSigningIn) {
                handleInput();
              }
            }}
            disabled={isSigningIn} // Disable the button while signing in
          ></PrimaryButton>
        </div>   
          {isSigningIn && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;